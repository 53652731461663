import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "d-flex align-items-center d-lg-none ms-2 me-n3 d-none",
  title: "Show header menu"
}
const _hoisted_6 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$store.state.uid > 0)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: "metronic",
              style: {"object-fit":"cover"},
              src: _ctx.profileUrl,
              onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imageLoadError && _ctx.imageLoadError(...args)))
            }, null, 40, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_KTUserMenu)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/text/txt001.svg" })
        ])
      ])
    ])
  ]))
}